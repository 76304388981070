import {createSlice} from '@reduxjs/toolkit';
import {iManageList} from '../../app/brand/campaign/manage/calendar/dto/IManageCalendar';
import {IPlannerOffersQuery} from '../../app/influencer/planner/dto/IPlanner';
import {Campaign} from '../../model/campaign/Campaign';

const initialPlannerOffers: IPlannerStore = {
    events: [],
    filterParams: {
        page: 1,
        perPage: 15,
        totalPages: 0,
    },
    campaigns: [],
    shouldOpenModal: false,
    triggerRescheduleList: false,
    previewEventModal: {
        isOpenPreviewEventModal: false,
        eventId: undefined,
    },
};

export interface IPlannerStore {
    events: iManageList[];
    filterParams: IPlannerOffersQuery;
    campaigns: Campaign[];
    selectedCampaign?: Campaign;
    shouldOpenModal?: boolean;
    triggerRescheduleList: boolean;
    previewEventModal: {
        isOpenPreviewEventModal?: boolean;
        eventId?: number;
    };
}

export const plannerAgency = createSlice({
    name: 'plannerAgency',
    initialState: initialPlannerOffers,
    reducers: {
        setEvents: (state, action) => {
            state.events = action.payload;
            return state;
        },
        setFilterParams: (state, action) => {
            state.filterParams = {
                ...state.filterParams,
                ...action.payload,
            };
            return state;
        },
        setCampaigns: (state, action) => {
            state.campaigns = action.payload;
            return state;
        },
        setSelectedCampaign: (state, action) => {
            state.selectedCampaign = action.payload;
            return state;
        },
        setShouldOpenModal: (state, action) => {
            state.shouldOpenModal = action.payload;
            return state;
        },
        setOpenPreviewModal: (state, action) => {
            state.previewEventModal = action.payload;
            return state;
        },
        setTriggerRescheduleList: (state, action) => {
            state.triggerRescheduleList = action.payload;
            return state;
        },
    },
});
