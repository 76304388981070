import { IOffer } from "../../../../model/offer/IOffer";

const campaignConceptContent = (offer: IOffer, t: any) => {
      return !!offer.description ? `
        <div>
          <h4 class="mt-10 text-upper">${t(
              'brand.campaign.book.offer.modal.campaign_concept',
          )}</h4>

          <p class="mb-1">${!!offer.description ? offer.description : '-'}</p>
        </div>
        ` : '';
    };

export default campaignConceptContent;