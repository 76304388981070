import React from 'react'
import { Button, Modal, ModalTitle } from 'react-bootstrap'
import ModalHeader from 'react-bootstrap/esm/ModalHeader';

interface IModalDownload {
    show: boolean;
    setModalData: (...args: any) => any;
    children: React.ReactNode;
    isLongTerm: boolean;
}

export default function ModalDownload({children, show, setModalData, isLongTerm}: IModalDownload) {
  return (
    <Modal show={show}>
        <ModalHeader>
            <ModalTitle>Download {isLongTerm ? 'Contracts' : 'Offers' }</ModalTitle>
        </ModalHeader>
        <Modal.Body>
            {children}
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={() => setModalData({show: false})}>Cancel</Button>
        </Modal.Footer>
    </Modal>
  )
}
