import {Button, Card} from 'react-bootstrap';
import {CustomImageHandler} from '../../../../../shared/components/CustomImageHandler';
import {IOffer} from '../../../../../model/offer/IOffer';
import {useTranslation} from 'react-i18next';
import {User} from '../../../../../model/user/User';
import React from 'react';
import {IOfferWithCampaign} from '../../../../influencer/profile/profile-view';
import {checkOfferType} from '../../../../../shared/functions/Functions';

interface IAcceptedOfferCardProps {
    offer: IOfferWithCampaign;
    openOfferAction: (user?: User, isLongTerm?: boolean) => void;
}

export function AcceptedOfferCard({offer, openOfferAction}: IAcceptedOfferCardProps) {
    const {t} = useTranslation();
    const hasStripe = offer?.isLongTerm || offer?.isAddonsOnly || offer?.isOnTop;

    return (
        <>
            <Card className="mb-2">
                <Card.Body className="d-flex justify-content-between position-relative overflow-hidden">
                    {hasStripe &&
                        <div className="diagonal-text">
                            <span className="diagonal-text-sm">
                                {t(`brand.campaign.briefing.overview_card.${checkOfferType(offer?.isLongTerm, offer?.isOnTop, offer?.isAddonsOnly)}`)}
                            </span>
                        </div>
                    }
                    <div className="d-flex align-items-center">
                        <CustomImageHandler classes="user-img-rounded mr-2"
                                            photoPath={offer?.user?.profilePhoto?.thumbnail}
                                            altTag="User Profile"/>
                        <div>
                            <h6 className="mb-0">{!!offer.user?.displayName ? offer?.user?.displayName : (offer?.user?.firstName + ' ' + offer?.user?.lastName)}</h6>
                            {offer.isLongTerm ?
                                <p className="mb-0 text-primary">
                                    {t('brand.campaign.book.offer.part_of_name', {campaign: offer?.campaign?.parent?.title ?? '-'})}
                                </p>
                                : <p className="mb-0 text-primary euro-symbol">{offer?.price}</p>}
                        </div>
                    </div>
                    {!!offer?.declineReason?.length &&
                        <div className="d-flex align-items-center">
                            <h6 className="mx-2 text-muted">
                                <span>Declined reasons:</span>
                                {offer?.declineReason?.map((item, index) => (
                                    <span key={index}>{t(`general.decline_reasons.${item}`)}
                                        {index === offer?.declineReason?.length - 1 ? '' : ', '}
                                    </span>
                                ))}
                            </h6>
                        </div>
                    }
                    <div className="d-flex align-items-center">
                        <Button onClick={() => openOfferAction(offer?.user, offer?.isLongTerm)}
                                variant="outline-primary">
                            {t('general.button.open')}
                        </Button>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};
