import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Modal, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import {MenuItem, TextField} from '@material-ui/core';
import {FiInfo} from 'react-icons/fi';
import {BsFillExclamationTriangleFill} from 'react-icons/bs';
import ToggleSwitch from '../../../../shared/components/ToggleSwitch';
import {UserService} from '../service';
import {toast} from 'react-toastify';
import {IProfileModal} from '../dto/IProfileModal';
import {ErrorToast} from '../../../../utils/toasters';
import {useTranslation} from 'react-i18next';
import {IGlobalPreference} from '../dto/IGlobalPreference';
import {actions} from '../../../../store/authentication/authRedux';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../store/rootReducer';

const initialPreference: IGlobalPreference = {
    isNotificationByEmailRequested: false,
    isNotificationInBrowserRequested: false,
    locale: 'en',
    isUnsubscribed: false,
};

function GlobalPreferencesModal({show, closeModal, errorMessage}: IProfileModal) {
    const [state, setState] = useState<IGlobalPreference>(initialPreference);
    const {userType} = useSelector((state: IAllStates) => state.auth.user) || {};
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const isBrand = userType?.includes('brand');
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
        setState(prev => ({...prev, [name]: name === 'isUnsubscribed' ? !event : event}));
    };

    useEffect(() => {
        show && UserService.getGlobalPreference()
            .then((data) => {
                setState(data);
            });
    }, [show]);

    const updatePreference = () => {
        UserService.updateGlobalPreference(state)
            .then(data => {
                toast.success('Successfully updated global preference');
                dispatch(actions.updateLanguage(data.locale));
                if (closeModal) {
                    closeModal();
                }
            }).catch(error => ErrorToast(error));
    };

    return (
        <>
            <Modal show={show} onHide={closeModal}>
                <Modal.Header>
                    <Modal.Title>
                        <h4 className="m-0">{t('brand.account.global_preferences')}</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group className="my-2">
                                <TextField
                                    value={state.locale}
                                    size="small"
                                    name="language"
                                    label={t('general.language')}
                                    onChange={(event => setState({...state, 'locale': event.target.value}))}
                                    select
                                    variant="outlined"
                                    fullWidth
                                >
                                    <MenuItem value="en">{t(`general.global_preferences.english`)}</MenuItem>
                                    <MenuItem value="de">{t(`general.global_preferences.german`)}</MenuItem>
                                </TextField>
                                {errorMessage && <div className="text-danger text-center my-2">
                                    <BsFillExclamationTriangleFill size={15}/>
                                    <span className="text-danger">
                                        {errorMessage}
                                    </span>
                                </div>}
                            </Form.Group>

                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            <h6>
                                {t('brand.account.global_preferences_modal.email_notifications')}
                            </h6>
                        </Col>
                        <Col className="col-12 d-flex justify-content-between my-2">
                            <div>
                                {t('brand.account.global_preferences_modal.active_campaign_notifications')}
                                <OverlayTrigger placement="top"
                                                overlay={<Tooltip
                                                    id="active_campaign_notifications-tooltip">
                                                    {t('brand.account.global_preferences_modal.active_campaign_notifications_tooltip')}
                                                </Tooltip>}>
                                    <FiInfo size={14} className="cursor-pointer ml-1"/>
                                </OverlayTrigger>
                            </div>
                            <div>
                                <ToggleSwitch
                                    small={true}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        changeHandler(e, 'isNotificationByEmailRequested')}
                                    id="a"
                                    checked={state.isNotificationByEmailRequested}
                                    name="isNotificationByEmailRequested"
                                />
                            </div>
                        </Col>
                        {!state.isNotificationByEmailRequested &&
                            <Col className="col-12 d-flex justify-content-between my-2">
                                <div>
                                    {t('brand.account.global_preferences_modal.mentions_notifications')}
                                    <OverlayTrigger placement="top"
                                                    overlay={<Tooltip
                                                        id="mentions_notifications-tooltip">
                                                        {t('brand.account.global_preferences_modal.mentions_notifications_tooltip')}
                                                    </Tooltip>}>
                                        <FiInfo size={14} className="cursor-pointer ml-1"/>
                                    </OverlayTrigger>
                                </div>
                                <div>
                                    <ToggleSwitch
                                        small={true}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e, 'isNotificationByMentionRequested')}
                                        id="d"
                                        checked={state.isNotificationByMentionRequested}
                                        name="isNotificationByMentionRequested"
                                    />
                                </div>
                            </Col>}
                        {!isBrand && <Col className="col-12 d-flex justify-content-between my-2">
                            {/* show only for users that are not brand */}
                            <div>
                                {t('brand.account.global_preferences_modal.campaign_notifications')}
                                <OverlayTrigger placement="top"
                                                overlay={<Tooltip
                                                    id="campaign_notifications-tooltip">
                                                    {t('brand.account.global_preferences_modal.campaign_notifications_tooltip')}
                                                </Tooltip>}>
                                    <FiInfo size={14} className="cursor-pointer ml-1"/>
                                </OverlayTrigger>
                            </div>
                            <div>
                                <ToggleSwitch
                                    small={true}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e, 'isNotificationInBrowserRequested')}
                                    id="b"
                                    checked={state.isNotificationInBrowserRequested}
                                    name="isNotificationInBrowserRequested"
                                />
                            </div>
                        </Col>}
                        <Col className="col-12 d-flex justify-content-between my-2">
                            <div>
                                {t('brand.account.global_preferences_modal.marketing_notifications')}
                            </div>
                            <div>
                                <ToggleSwitch
                                    small={true}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e, 'isUnsubscribed')}
                                    name="isUnsubscribed"
                                    id="c"
                                    checked={!state.isUnsubscribed}
                                />
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={closeModal}>
                        {t('general.button.close')}
                    </Button>
                    <Button variant="primary" onClick={updatePreference}>
                        {t('general.button.save')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default GlobalPreferencesModal;
