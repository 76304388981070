const distributionContent = (t: any) => {
    return `
      <div class="avoid-brake">
          <div>
            <h3 class="pt-10 text-upper">${t(
            'brand.campaign.briefing.step7.distribution',
        )}</h3>
          </div>

          <div>
            <h4 class="mb-1">${t(
                'brand.campaign.briefing.step7.organic_reach_title',
            )}
            </h4>

            <p>${t('brand.campaign.briefing.step7.organic_reach_desc')}</p>
          </div>
      </div>
    `;
};

export default distributionContent