import React, {SetStateAction, useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {Campaign} from '../../../model/campaign/Campaign';
import AlertBox from '../../../shared/components/alert/AlertBox';
import {CustomAutocomplete} from '../../../shared/components/Autocomplete';
import usePagination from '../../../shared/hooks/usePagination';
import {IAllStates} from '../../../store/rootReducer';
import {ErrorToast} from '../../../utils/toasters';
import {ILocation} from '../../brand/campaign/messages/components/filters/MessageFilters';
import ReportingPerformance from '../../influencer/reporting/components/ReportingPerformance';
import ReportingTasks from '../../influencer/reporting/components/ReportingTasks';
import {
    IPerformanceSummary,
    IReportingSummary,
    IReportingTasksSummary
} from '../../influencer/reporting/dto/IReporting';
import ReportingPerCampaign from './components/ReportingPerCampaign';
import {AgencyReportingService} from './service';

export function AgencyReporting() {
    const {filterParams, setFilterParams} = usePagination({});
    const [updateReports, setUpdateReports] = useState(false);
    const {state} = useLocation();
    const [showItems, setShowItems] = useState({
        showReporting: window.innerWidth > 600,
        showPerformance: window.innerWidth > 600
    });
    const [summary, setSummary] = useState<IReportingSummary>();

    const {selectedInfluencer} = useSelector((reduxState: IAllStates) => reduxState.agency);
    const [selectedFilterCampaign, setSelectedFilterCampaign] = useState<Campaign>();
    const [campaigns, setCampaigns] = useState<Campaign[]>([]);
    const {t} = useTranslation();
    const history = useHistory();

    const handleShowFields = (key: 'showReporting' | 'showPerformance') => {
        setShowItems(prev => ({...prev, [key]: !prev[key]}));
    }
    const changeCampaignFilterHandler = (paramKey: string, paramValue: string | number) => {
        const selectedCampaign = campaigns?.find(campaign => campaign?.offerId === +paramValue);
        setFilterParams((prev: any) => ({
            ...prev,
            campaignId: selectedCampaign?.id,
            isLongTerm: selectedCampaign?.offers?.[0]?.isLongTerm ?? null
        }));
        setSelectedFilterCampaign(selectedCampaign)
    };

    useEffect(() => {
        AgencyReportingService.getSummary(selectedInfluencer?.id).then(response => {
            setSummary(response);
        }).catch(error => ErrorToast(error));
    }, [updateReports, selectedInfluencer?.id]);

    useEffect(() => {
        AgencyReportingService.getReportingCampaigns({
            ...filterParams,
            isLongTerm: null,
            influencerId: selectedInfluencer?.id,
            campaignId: ((state as ILocation)?.campaignId || filterParams?.campaignId) ?? ''
        }).then(response => {
            if ((state as ILocation)?.campaignId) {
                const campaignId = (state as ILocation)?.campaignId;
                setSelectedFilterCampaign(response?.data?.find((campaign: Campaign) => campaign.id === campaignId))
            }
            setCampaigns(response.data?.map((campaign: Campaign) => ({
                ...campaign,
                offerId: campaign?.offers?.[0]?.id
            })));
        }).catch(error => ErrorToast(error));
    }, [filterParams, selectedInfluencer?.id]);

    return (
        <Row className="pb-5">
            <AlertBox image="/icons/img-warning.svg"
                      hasButton
                      classCol="col-12 mb-4"
                      variant="primary"
                      title={t('influencer.invoice.invoice_alert_title')}
                      desc={t('influencer.invoice.invoice_alert_desc')}
                      textButton={t('influencer.invoice.go_to')}
                      functionBtn={() => history.push('/agency/invoices')}
            />
            {showItems?.showReporting && <Col md={6} className="my-3 my-md-0 order-2 order-md-1">
                <ReportingTasks
                    reportingTasks={summary?.reportingTasksSummary as IReportingTasksSummary}/>
            </Col>}
            {showItems?.showPerformance && <Col md={6} className="my-3 my-md-0 order-3 order-md-2">
                <ReportingPerformance
                    performance={summary?.performanceSummary as IPerformanceSummary}/>
            </Col>}
            <Col xs={12} className="order-1 order-md-3 mt-md-3">
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <CustomAutocomplete dataArray={campaigns} isWithImage={false} inputLabel="title"
                                        setFilterState={setFilterParams} disableClearable={false}
                                        changeEventKey="name" queryFilterKey="offerId"
                                        key={selectedFilterCampaign?.offerId}
                                        isLongSelected={selectedFilterCampaign?.offers?.[0]?.isLongTerm}
                                        classes="w-md-25" defaultArray={selectedFilterCampaign as unknown as Campaign[]}
                                        inputLabelTranslation={t('influencer.planner.selectCampaign')}
                                        handleChangeParams={changeCampaignFilterHandler}/>
                </div>
            </Col>
            <Col xs={12} className="order-4">
                <ReportingPerCampaign handleShowFields={handleShowFields} filterParams={filterParams}
                                      setFilterParams={setFilterParams}
                                      setUpdateReports={setUpdateReports as React.Dispatch<SetStateAction<boolean>>}
                                      updateReports={updateReports}/>
            </Col>
        </Row>
    );
};
