import {Button} from 'react-bootstrap';
import React, {SetStateAction, useState} from 'react';
import {iFilterInfluencers, iManageFilters} from '../dto/IManageCalendar';
import {TFunction} from 'react-i18next';
import {CustomAutocomplete} from '../../../../../../shared/components/Autocomplete';
import {IContentModal} from '../../../book/offer/dto/IContentModal';

interface iCalendarViewsProps {
    view: string,
    icon: string
}

interface iCalendarHeaderFiltersProps {
    setFilterInfluencers: React.Dispatch<SetStateAction<iFilterInfluencers>>;
    calendarViews: iCalendarViewsProps[];
    changeBtnFormat: (props: string) => void;
    selectedCalendarView: string;
    t: TFunction<'translation'>;
    filterParams: iManageFilters;
    influencers: IContentModal[];
    handleChangeParams: (paramKey: string, paramValue: string | number, isLongTerm?: boolean | null) => void;

}

export const CalendarHeaderFilters = ({
                                          calendarViews,
                                          changeBtnFormat,
                                          selectedCalendarView,
                                          t,
                                          influencers,
                                          filterParams,
                                          handleChangeParams,
                                          setFilterInfluencers,
                                      }: iCalendarHeaderFiltersProps) => {

    // const userDropdown = influencers?.map(user => ({...user, offerId: user?.id, offers: [user]}))

    return (
        <div className="row flex-column flex-xl-row w-100 justify-content-between mb-2 mx-0 align-items-center">
            <div className="col-12 col-xl-8 px-0 d-flex justify-content-center justify-content-xl-start ">
                <div className="w-md-50">
                    <CustomAutocomplete
                        dataArray={influencers}
                        // defaultArray={(influencers)?.filter((item: any) =>
                        //     filterParams.offerIds?.find((id) => item.id === id),
                        // )}
                        isWithImage={true}
                        isMultiple={true}
                        queryFilterKey="offerIds"
                        changeEventKey="displayName"
                        inputLabel="user.displayName"
                        photoLabel="user.profilePhoto"
                        handleChangeParams={handleChangeParams}
                        inputLabelTranslation={t('brand.campaign.manage.influencerName')}
                        // setFilterState={setFilterInfluencers}
                        classes="col-12 px-0"/>
                </div>
            </div>
            <div
                className="col-12 col-xl-4 pr-0 d-none d-sm-flex justify-content-center justify-content-xl-end pr-0 align-items-center mt-3 mt-xl-0">
                {calendarViews?.map((view) => {
                    return (
                        <Button
                            key={view?.view}
                            variant="icon"
                            className="mr-2"
                            onClick={() => changeBtnFormat(view?.view)}
                        >
                            <img
                                src={`/icons/${
                                    view?.view === selectedCalendarView
                                        ? `${view?.icon}-active`
                                        : view?.icon
                                }.svg`}
                                style={{width: 20, height: 20}}
                                alt={`${view?.view} view icon`}
                            />
                        </Button>
                    );
                })}
            </div>
        </div>
    )
}