import { ICampaignOverview } from "../../../../model/briefing/overview/ICampaignOverview";
import { IOffer } from "../../../../model/offer/IOffer";
import { checkContractType } from "../../../functions/Functions";

const contractType = (t: any, offer: IOffer, campaignOverview: ICampaignOverview) => {
  return `
        <div class="avoid-brake mt-10">
            <div>
                <h3 class="text-upper">${t('brand.campaign.briefing.contract_type.contract_type')}</h3>
                <h4>${t(`brand.campaign.briefing.contract_type.${checkContractType(campaignOverview?.isLongTerm ?? true, !!campaignOverview?.parent?.id)}.title`)}</h4>
                <p class="mb-2">${t(`brand.campaign.briefing.contract_type.${checkContractType(campaignOverview?.isLongTerm ?? true, !!campaignOverview?.parent?.id)}.description`)}</p>
                ${!!campaignOverview?.parent?.id ?
                    `<h5 class="d-inline-block font-weight-normal">Part of: </h5> <h5 class="d-inline-block">${campaignOverview?.parent?.title}</h5>`
                    : ''}
            </div>
        </div>
    `;
};

export default contractType;
