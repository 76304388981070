import React, {ChangeEvent, useEffect, useState} from 'react';
import {Button, Col, Form, Modal, Row} from 'react-bootstrap';
import {MenuItem, TextField} from '@material-ui/core';
import ToggleSwitch from '../../../../shared/components/ToggleSwitch';
import {IProfileModal} from '../dto/IProfileModal';
import {ICompany} from '../dto/ICompany';
import {Bank} from '../../../../model/shared/Bank';
import {Contact} from '../../../../model/shared/Contact';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../store/rootReducer';
import {UserService} from '../service';
import {actions} from '../../../../store/authentication/authRedux';
import {ErrorToast, SuccessToast} from '../../../../utils/toasters';
import {useTranslation} from 'react-i18next';
import {UserRoles} from '../../../../enum/UserRoles';
import {validateVAT} from '../../../../shared/functions/Functions';

const initialBank: Bank = {
    accountCountry: '',
    accountPhone: '',
    accountBankName: '',
    accountIban: '',
    accountSwift: '',
    accountPaymentType: '',
};
const initialContact: Contact = {
    country: '',
    phone: '',
};
const initialBilling: ICompany = {
    bank: initialBank,
    contact: initialContact,
};

function BillingInfoModal({show, closeModal, userType}: IProfileModal) {
    const [toggle, setToggle] = useState(true);
    const [state, setState] = useState<ICompany>(initialBilling);
    const {country} = useSelector((states: IAllStates) => states.enums);
    const [updateInitialData, setUpdateInitialData] = useState(false);
    const [vatError, setVatError] = useState(false);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const handleSwitch = (checked: boolean) => {
        setToggle(checked);
        setState({
            ...state,
            contact: {
                ...state.contact,
                smallBusiness: checked,
            },
        });
    };
    useEffect(() => {
        if (userType === UserRoles.BRAND_MASTER) {
            show && UserService.getCompanyInfo()
                .then((data) => {
                    setState(data.data);
                    setUpdateInitialData(true);
                }).catch(error => ErrorToast(error));
        } else if (userType === UserRoles.INFLUENCER) {
            show && UserService.getInfluencerBilling()
                .then((response) => {
                    setState(response.data);
                    setUpdateInitialData(true);
                    setToggle(response.data.contact.smallBusiness ?? false);
                }).catch(error => ErrorToast(error));
        } else {
            show && UserService.getAgencyBilling()
                .then((response) => {
                    setState(response.data);
                    setUpdateInitialData(true);
                    setToggle(response.data.contact.smallBusiness ?? false);
                }).catch(error => ErrorToast(error));
        }
        return () => setUpdateInitialData(false);
        // eslint-disable-next-line
    }, [show]);

    function handleChange(evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, isContact = false, isBank = false) {
        const value = evt.target.value?.trim();
        if (isContact) {
            setState({
                ...state,
                contact: {
                    ...state.contact,
                    [evt.target.name]: value,
                },
            });
        } else if (isBank) {
            setState({
                ...state,
                bank: {
                    ...state.bank,
                    [evt.target.name]: value,
                },
            });
            // if (evt.target.name === 'accountCountry' && value !== 'AT') {
            //     setState(prevState => ({
            //         ...prevState,
            //         contact: {
            //             ...prevState.contact,
            //             smallBusiness: false,
            //         },
            //     }));
            //     setToggle(false);
            // }
        } else {
            setState({
                ...state,
                [evt.target.name]: value,
            });
        }
        setVatError(false);
    }

    const updateCompanyBilling = () => {
        UserService.updateCompanyBilling(state)
            .then(response => {
                dispatch(actions.updateComletionInfo(response.completionInfo));
                SuccessToast(t('general.toasts.successUpdatedBilling'));
                if (closeModal) {
                    closeModal();
                }
            }).catch(error => ErrorToast(error));

    };
    const updateAgencyBilling = () => {
        UserService.updateAgencyBilling(state)
            .then(response => {
                dispatch(actions.updateComletionInfo(response.completionInfo));
                SuccessToast(t('general.toasts.successUpdatedBilling'));
                if (closeModal) {
                    closeModal();
                }
            }).catch(error => ErrorToast(error));
    };
    const updateInfluencerBilling = () => {
        UserService.updateInfluencerBilling(state)
            .then(response => {
                dispatch(actions.updateComletionInfo(response.completionInfo));
                SuccessToast(t('general.toasts.successUpdatedBilling'));
                if (closeModal) {
                    closeModal();
                }
            }).catch(error => ErrorToast(error));
    };
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if ((!state.contact.smallBusiness) && !validateVAT(String(state.contact.uidNumber))) {
            setVatError(true);
            return;
        }

        if (userType === UserRoles.BRAND_MASTER) {
            updateCompanyBilling();
        } else if (userType === UserRoles.INFLUENCER) {
            updateInfluencerBilling();
        } else {
            updateAgencyBilling();
        }
    };
    return (
        <>
            {updateInitialData &&
                <Modal show={show} onHide={closeModal} size="lg">
                    <Form
                        onSubmit={(event) => onSubmit(event)}>
                        <Modal.Header>
                            <Modal.Title>
                                <h4 className="m-0">
                                    {t('brand.account.billing_information')}
                                </h4>
                                <p className="text-black-50 font-14 m-0">
                                    {t('brand.account.billing_information_modal.update_your_billing_info')}
                                </p>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h6 className="mb-4">{t('brand.account.billing_information_modal.receiver')}</h6>
                            <Row className="mb-4">
                                <Form.Group className="mb-4 col-6">
                                    <TextField
                                        onChange={e => handleChange(e, true)}
                                        defaultValue={state?.contact?.invoiceFirstName}
                                        fullWidth
                                        required
                                        label={`${t('general.first_name')}`}
                                        variant="outlined"
                                        size="small"
                                        name="invoiceFirstName"
                                        className="pr-2 pr-md-0 pr-md-0"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-4 col-6">
                                    <TextField
                                        onChange={e => (handleChange(e, true))}
                                        defaultValue={state?.contact?.invoiceLastName}
                                        fullWidth
                                        required
                                        label={`${t('general.last_name')}`}
                                        variant="outlined"
                                        size="small"
                                        name="invoiceLastName"
                                        className="pl-2 pl-md-0"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-4 col-12">
                                    <TextField
                                        onChange={e => (handleChange(e, true))}
                                        defaultValue={state?.contact?.companyName}
                                        fullWidth
                                        required
                                        label={`${t('brand.account.billing_information_modal.company_name')}`}
                                        variant="outlined"
                                        size="small"
                                        name="companyName"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-4 col-6">
                                    <TextField
                                        onChange={e => (handleChange(e, false, true))}
                                        defaultValue={state?.bank?.accountPhone}
                                        fullWidth
                                        required
                                        label={`${t('general.phone_number')}`}
                                        variant="outlined"
                                        size="small"
                                        name="accountPhone"
                                        className="pr-2 pr-md-0"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-4 col-6">
                                    <TextField
                                        fullWidth
                                        onChange={e => (handleChange(e, true))}
                                        defaultValue={state?.contact?.email}
                                        label={`${t('general.email')}`}
                                        variant="outlined"
                                        required
                                        size="small"
                                        name="email"
                                        className="pl-2 pl-md-0"
                                    />
                                </Form.Group>
                            </Row>
                            <h6 className="mb-4">{t('brand.account.billing_information_modal.address')}</h6>
                            <Row className="mb-4">
                                <Form.Group className="mb-4 col-12">
                                    <TextField
                                        onChange={e => (handleChange(e, true))}
                                        defaultValue={state?.contact?.street}
                                        fullWidth
                                        required
                                        label={`${t('general.address.street')}`}
                                        variant="outlined"
                                        size="small"
                                        name="street"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-4 col-6">
                                    <TextField
                                        onChange={e => (handleChange(e, true))}
                                        defaultValue={state?.contact?.city}
                                        fullWidth
                                        required
                                        label={`${t('general.address.city')}`}
                                        variant="outlined"
                                        size="small"
                                        name="city"
                                        className="pr-2 pr-md-0"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-4 col-6">
                                    <TextField
                                        onChange={e => (handleChange(e, true))}
                                        defaultValue={state?.contact?.zip}
                                        fullWidth
                                        required
                                        label={`${t('general.address.postal_code')}`}
                                        variant="outlined"
                                        size="small"
                                        name="zip"
                                        className="pl-2 pl-md-0"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-4 col-6">
                                    <TextField
                                        defaultValue={state?.bank?.accountCountry}
                                        onChange={e => (handleChange(e, false, true))}
                                        size="small"
                                        name="accountCountry"
                                        required
                                        label={t('general.address.country')}
                                        select
                                        variant="outlined"
                                        fullWidth
                                        className="pr-2 pr-md-0"
                                    >
                                        {country.map((countryName) => (
                                            <MenuItem key={countryName}
                                                      value={countryName}>{t(`general.countries.${countryName}`)}</MenuItem>
                                        ))
                                        }
                                    </TextField>
                                </Form.Group>
                                <Form.Group className="mb-4 col-6">
                                    <TextField
                                        defaultValue={state?.contact?.province}
                                        onChange={e => (handleChange(e, true))}
                                        fullWidth
                                        required
                                        label={`${t('general.address.state')}`}
                                        variant="outlined"
                                        size="small"
                                        name="province"
                                        className="pl-2 pl-md-0"
                                    />
                                </Form.Group>
                            </Row>
                            {[UserRoles.INFLUENCER, UserRoles.AGENCY, UserRoles.AGENCY_MASTER, UserRoles.BRAND, UserRoles.BRAND_MASTER].includes(userType as UserRoles) && (
                                <h6 className="mb-4">{t('brand.account.billing_information_modal.payment_info')}</h6>
                            )}
                            <Row className="mb-4">
                                {((userType === UserRoles.INFLUENCER) || (userType === UserRoles.AGENCY) || (userType === UserRoles.AGENCY_MASTER)) && (
                                    <>
                                        <Col md="6">
                                            <TextField
                                                defaultValue={state?.bank?.accountBankName}
                                                onChange={e => (handleChange(e, false, true))}
                                                fullWidth
                                                label={t('brand.account.billing_information_modal.bank_name')}
                                                variant="outlined"
                                                size="small"
                                                name="accountBankName"
                                                required
                                            />
                                        </Col>
                                        <Col md="6" className="mt-4 mt-md-0">
                                            <TextField
                                                defaultValue={state?.bank?.accountIban}
                                                onChange={e => (handleChange(e, false, true))}
                                                fullWidth
                                                label={t('brand.account.billing_information_modal.iban')}
                                                variant="outlined"
                                                size="small"
                                                required
                                                name="accountIban"
                                            />
                                        </Col>
                                        <Col md="6" className="mt-4">
                                            <TextField
                                                defaultValue={state?.bank?.accountSwift}
                                                onChange={e => (handleChange(e, false, true))}
                                                fullWidth
                                                label={t('brand.account.billing_information_modal.swift')}
                                                variant="outlined"
                                                size="small"
                                                name="accountSwift"
                                            />
                                        </Col>
                                    </>
                                )}
                                    {[UserRoles.INFLUENCER, UserRoles.AGENCY, UserRoles.AGENCY_MASTER, UserRoles.BRAND, UserRoles.BRAND_MASTER].includes(userType as UserRoles) && (
                                      <Col md={6} className={`${[UserRoles.BRAND, UserRoles.BRAND_MASTER].includes(userType as UserRoles) ? 'mt-0' : 'mt-4'}`}>
                                          <TextField
                                            defaultValue={state?.contact?.uidNumber}
                                            onChange={e => (handleChange(e, true))}
                                            fullWidth
                                            required={!state?.contact?.smallBusiness}
                                            label={t('brand.account.billing_information_modal.vat')}
                                            variant="outlined"
                                            size="small"
                                            name="uidNumber"
                                            error={vatError}
                                            helperText={vatError ? 'Incorrect input' : ''}
                                          />
                                      </Col>
                                    )}
                                </Row>
                            {![UserRoles.BRAND, UserRoles.BRAND_MASTER].includes(userType as UserRoles) &&
                              <>
                                <h6 className="mb-4">{t('brand.account.billing_information_modal.billing_details')}</h6>
                                <Row className="">
                                    <Col className="col-12 mb-4">
                                        <div className="d-flex">
                                            <p className="mb-0 mr-5">{t('brand.account.billing_information_modal.small_business_owner')}</p>
                                            <ToggleSwitch
                                                onChange={handleSwitch}
                                                id="c"
                                                checked={state?.contact?.smallBusiness}
                                            />
                                        </div>
                                        <div>
                                            <p className="font-12 mb-0 mt-2">{t('brand.account.billing_information_modal.small_business_regulation')}</p>
                                        </div>
                                    </Col>
                                </Row>
                              </>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary" onClick={closeModal}>
                                {t('general.button.close')}
                            </Button>
                            <Button variant="primary"
                                    type="submit">
                                {t('general.button.save')}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            }
        </>

    );
}

export default BillingInfoModal;
