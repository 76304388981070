import { ICampaignOverview } from "../../../../model/briefing/overview/ICampaignOverview";
import { IOffer } from "../../../../model/offer/IOffer";
import { checkOfferTypePDF, formatDate } from "../../../functions/Functions";

const titleContract = (offer: IOffer, campaignOverview: ICampaignOverview, isBrandOrBrandMaster: boolean, t: any) => {
  return `
      <div class="my-4">
        <h2 class="text-center mb-0">${
          campaignOverview?.isCharity
            ? "Barter Deal Contract"
            : "Paid Deal Contract"
          }
        </h2>
        <h4 class="text-center mb-0">
          ${t('brand.campaign.briefing.overview_card.'+checkOfferTypePDF(offer?.isLongTerm, offer?.isOnTop, offer?.isAddonsOnly, isBrandOrBrandMaster, !campaignOverview?.isLongTerm ))}
        </h4>
        <p class="text-center">
          ${campaignOverview?.isLongTerm ? "Contract ID" : "Offer ID"}: ${offer?.id+""}
          <span class="pl-1"> 
            accepted on ${formatDate(offer?.createdAt)}
          </span> 
        </p>
      </div>
    `;
};

export default titleContract;
