import React, {useEffect, useState} from 'react';
import {Button, Card, Col, OverlayTrigger, Row} from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Campaign} from '../../../../model/campaign/Campaign';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import EmptyState from '../../../../shared/components/placeholder/EmptyState';
import {getPlannerCardIcons, truncateString} from '../../../../shared/functions/Functions';
import {plannerInfluencer} from '../../../../store/influencer/plannerSlice';
import {IAllStates} from '../../../../store/rootReducer';
import {IPlannerOffers, IRescheduleNotify} from '../dto/IPlanner';
import {InfluencerPlannerService} from '../service';
import {RescheduleCard} from './RescheduleCard';
import CardLayout from '../../../../shared/components/card/CardLayout';
import { Pagination } from '../../../../shared/components/Pagination';

interface IPlannerCardProps {
    offers: IPlannerOffers;
}

const PlannerCard = ({offers}: IPlannerCardProps) => {
    const {t} = useTranslation();
    const [rescheduleNotify, setRescheduleNotify] = useState<IRescheduleNotify[]>([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const statusesToDisplay = ['scheduled', 'approved'];
    const {filterParams, triggerRescheduleList} = useSelector((state: IAllStates) => state.plannerInfluencer);

    useEffect(() => {
        InfluencerPlannerService.getRescheduleNotify()
            .then((response) => {
                setRescheduleNotify(response);
            });
    }, [triggerRescheduleList]);

    const selectCampaignHandler = (campaign: Campaign) => {
        dispatch(plannerInfluencer.actions.setFilterParams({campaignId: campaign?.id}));
        dispatch(plannerInfluencer.actions.setSelectedCampaign(campaign));
        dispatch(plannerInfluencer.actions.setShouldOpenModal(true));
    };
    return (
        <>
            <Col xs={12} className="px-0 mb-3">
                <Button variant="outline-primary" className="w-100"
                        onClick={() => history.push('/influencer/reporting', filterParams?.campaignId && {campaignId: Number(filterParams?.campaignId) ?? ''})}>
                    &gt;&gt; {t('influencer.planner.sendReportings')}
                </Button>
            </Col>
            {rescheduleNotify.map((item) => (
                <RescheduleCard key={item?.id} rescheduleData={item}/>
            ))}
            <CardLayout>
                <div className="mb-3">
                    <h5 className="text-center">
                        {t('influencer.planner.ongoingCampaigns')}
                    </h5>
                </div>
                <div className="bg-muted default-radius p-1 p-md-2">
                    <Row className="align-items-md-end">
                        <Col xs={6} className="px-md-0">
                            <h5 className="text-primary">{offers?.data?.length}</h5>
                            <span>{t('general.campaigns')}</span>
                        </Col>
                        {statusesToDisplay?.map(status => getPlannerCardIcons({status, offers, t}))}
                    </Row>
                </div>
                <Row className="mt-2">
                    {offers?.data?.length ? offers?.data?.map((influencer) => {
                        return (
                            <React.Fragment key={influencer?.id}>
                                <Col xs={6}
                                        className="d-flex flex-lg-column flex-xl-row align-items-start word-break my-1 px-0 pl-2">
                                    <OverlayTrigger placement="left" overlay={
                                        <Tooltip id={`tooltip-top-${influencer?.id}`}>
                                            <span>{influencer?.campaign?.title}</span>
                                        </Tooltip>
                                    }
                                    >
                                        <div className="cursor-pointer d-flex align-items-center"
                                                onClick={() => selectCampaignHandler(influencer?.campaign)}>
                                            <CustomImageHandler
                                                altTag="Influencer logo"
                                                classes="small-rounded-logo"
                                                thumbnailPath={influencer?.campaign?.campaignImages[0]?.thumbnail}
                                                photoPath={influencer?.campaign?.campaignImages[0]?.thumbnail ?? null}
                                            />
                                            <div className="d-flex flex-column ml-2 ">
                                                <span
                                                    className="text-dark">{truncateString(influencer?.campaign?.title ?? '-', 12)}
                                            </span>
                                                {influencer?.isLongTerm &&
                                                    <div className={'custom-badge long-term'}>
                                                        {t(`brand.campaign.briefing.overview_card.linked`)}
                                                    </div>}
                                                {influencer?.isAddonsOnly &&
                                                    <div className={'custom-badge additional ml-1'}>
                                                        {t('influencer.offer_management.modal.additional')}
                                                    </div>}
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                </Col>
                                <Col xs={3} className="px-1 d-flex justify-content-center align-items-center">
                                    <span className="text-info">
                                        {influencer?.totalPlanned?.scheduled ?? 0} /{' '}
                                        {influencer?.totalPlanned?.totalNbPosts}
                                    </span>
                                </Col>
                                <Col xs={3} className="px-1 d-flex justify-content-center align-items-center">
                                    <span className="text-primary">
                                        {influencer?.totalPlanned?.approved ?? 0} /{' '}
                                        {influencer?.totalPlanned?.totalNbPosts}
                                    </span>
                                </Col>

                            </React.Fragment>
                        )
                    }) : <EmptyState/>}
                </Row>
                {filterParams?.totalPages > 1 &&
                    <Col xs={12} className='px-0'>
                        <div className="d-flex justify-content-end">
                            <Pagination classWrapper='pb-0' page={filterParams?.page ?? 1} totalPages={filterParams?.totalPages}
                                        handlePagination={(num) => dispatch(plannerInfluencer.actions.setFilterParams({...filterParams, page: num}))}/>
                        </div>
                    </Col>
                }
            </CardLayout>
        </>
    );
};

export default PlannerCard;
