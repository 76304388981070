import { ICampaignOverview } from "../../../../../model/briefing/overview/ICampaignOverview";
import { User } from "../../../../../model/user/User";

const briefingCampaignTitle = (campaignOverview: ICampaignOverview, user: User) => {
    return (`
        <div class="avoid-brake">
            <h2>${campaignOverview?.title}</h2>
            <h5 class="text-primary">by ${campaignOverview?.company?.displayName}</h5>
        </div>
        `
    );
};

export default briefingCampaignTitle;